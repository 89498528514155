/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect } from 'react'
import PropTypes from "prop-types"
import AOS from 'aos'
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header"
//import HeaderForPPC from "./Header/Inner-header"
import "../scss/layout.scss"
import Footer from  "./Footer"
const Layout = ({ children,pathname,location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    AOS.init({
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  
  },[])
  return (
    <>
     {( pathname !== "metal-buildings-for-sale" ) && <Header siteTitle={data.site.siteMetadata.title} location = {location} /> }
     {( pathname === "metal-buildings-for-sale" ) ?  <>{children}</> : <main>{children}</main> }
     {( pathname !== "metal-buildings-for-sale" ) && <Footer /> }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
