import React, { useEffect, useState} from "react"
import {ArrowRight} from '../../Icons'
import HorseBarn from "../../../images/menu_images/horse-barn.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby'

const BarnDropDown = (props) =>
{ 
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    },0)

  })
  return (
    <NavDropdown title="Barns" id="basic-nav-dropdown3">
      <Row noGutters>
        <Col lg="4">
          <Link className='dropdown-item' to='/barns/regular-roof-barns'>Regular Roof Barns<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/barns/raised-center-barns'>Raised Center Barns<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/barns/straight-roof-barns'>Straight Roof Barns<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>                        
          <Link className='dropdown-item view' to='/metal-barns'>View All Metal Barns<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>                        
        </Col>
        <Col className='dropdown-menu-rightSide-cnt'>
          <div className='dropdown-menu-rightSide'>
            <div className='innnerItem'>
              <h5 className='title'>Metal Barns</h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && <div style={{ position: 'absolute', background: 'gray', left: 0, right: 0, display:'flex', top:'0', bottom:'0', alignItems:'center', justifyContent:'center', color:'#fff'}}>Loading</div>}
                {windowLoaded && <img src={HorseBarn} alt='Horse Barns' className="main-img" />}                
              </figure>
              <Link to='/metal-barns' className='text-secondary btn btn-link icon-right'>Explore <ArrowRight stroke='#cb0000' ArrowDir='right' width="16px" height="16px" /></Link>
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )
}
export default BarnDropDown;