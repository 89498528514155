import React, { useState, useEffect } from "react"
import { ArrowRight } from '../../Icons'
import MetalBuildingsDropdown from "../../../images/menu_images/metal-buildings-dropdown.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby'

const OtherDropDown = (props) => {
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    }, 0)

  })
  return (
    <NavDropdown title="Metal Buildings" id="basic-nav-dropdown4">
      <Row noGutters>
        <Col lg="4">
          <Link className='dropdown-item' to='/metal-buildings/clear-span-buildings'>Clear Span Buildings <ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/metal-buildings/workshop-buildings'>Workshop Buildings<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/metal-buildings/storage-buildings'>Storage Buildings<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/metal-buildings/insulated-buildings'>Insulated Buildings<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item view' to='/metal-buildings'>View All Metal Buildings<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
        </Col>
        <Col className='dropdown-menu-rightSide-cnt'>
          <div className='dropdown-menu-rightSide'>
            <div className='innnerItem'>
              <h5 className='title'>Metal Buildings </h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      background: "gray",
                      left: 0,
                      right: 0,
                      display: "flex",
                      top: "0",
                      bottom: "0",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    Loading
                  </div>
                )}
                {windowLoaded && (
                  <img
                    src={MetalBuildingsDropdown}
                    alt="Metal Garage"
                    className="main-img"
                  />
                )}               
              </figure>
              <Link
                to="/metal-buildings"
                className="text-secondary btn btn-link icon-right"
              >
                Explore{" "}
                <ArrowRight
                  stroke="#cb0000"
                  ArrowDir="right"
                  width="16px"
                  height="16px"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )

}
export default OtherDropDown;

