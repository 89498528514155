import React, { useState, useEffect } from "react"
import { ArrowRight } from '../../Icons'
import AboutUsDropdown from "../../../images/menu_images/about-us-dropdown.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby'

const PaymentDropDown = (props) => {
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    }, 0)

  })
  return (
    <NavDropdown title="About Us" id="basic-nav-dropdown">

      <Row noGutters>
        <Col lg="4">         
          <Link className='dropdown-item' to='/about' >About Us<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/contact' >Contact Us<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>          
        </Col>
        <Col className='dropdown-menu-rightSide-cnt'>
          <div className='dropdown-menu-rightSide'>
            <div className='innnerItem'>
              <h5 className='title'>About Us</h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && <div style={{ position: 'absolute', background: 'gray', left: 0, right: 0, display: 'flex', top: '0', bottom: '0', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>Loading</div>}
                {windowLoaded && <img src={AboutUsDropdown} alt='About Us' className="main-img" />}               
              </figure>          
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )
}
export default PaymentDropDown;