import React, { useState, useEffect } from "react"
import { ArrowRight } from "../../Icons"
import MetalGarageDropdown from "../../../images/menu_images/metal-garage-dropdown.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const CarportsDropDown = props => {
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    }, 0)
  })
  return (
    <NavDropdown title="Garages" id="basic-nav-dropdown2">
      <Row noGutters>
        <Col lg="4">
          <Link
            className="dropdown-item"
            to="/metal-garages/single-car-garages"
          >
            Single Car Garages{" "}
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link
            className="dropdown-item"
            to="/metal-garages/double-car-garages"
          >
            Double Car Garages{" "}
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link className="dropdown-item" to="/metal-garages/triple-wide-garages">
            Triple Wide Garages{" "}
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link className="dropdown-item" to="/metal-garages/rv-garages">
            RV Garages{" "}
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link className="dropdown-item view" to="/metal-garages">
            View All Metal Garages
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
        </Col>
        <Col className="dropdown-menu-rightSide-cnt">
          <div className="dropdown-menu-rightSide">
            <div className="innnerItem">
              <h5 className="title">Metal Garages</h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      background: "gray",
                      left: 0,
                      right: 0,
                      display: "flex",
                      top: "0",
                      bottom: "0",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    Loading
                  </div>
                )}
                {windowLoaded && (
                  <img
                    src={MetalGarageDropdown}
                    alt="Metal Garage"
                    className="main-img"
                  />
                )}               
              </figure>
              <Link
                to="/metal-garages"
                className="text-secondary btn btn-link icon-right"
              >
                Explore{" "}
                <ArrowRight
                  stroke="#cb0000"
                  ArrowDir="right"
                  width="16px"
                  height="16px"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )
}

export default CarportsDropDown
