
import PropTypes from "prop-types"
import React from 'react';

import NabbarTop from '../NavbarTop';
import { Navbar, Nav, Container} from 'react-bootstrap';
import CarportsDropDown from './dropdown/carports'
import GaragesDropDown from './dropdown/garages'
import BarnDropDown from './dropdown/barn'
import OtherDropDown from './dropdown/other'
import DealersDropDown from './dropdown/dealers'
import PaymentDropDown from './dropdown/payment'
import ResourcesDropDown from './dropdown/resources'
import { Logo } from '../Icons';

class Header extends React.Component {
  constructor(props) { 
    super(props);
    this.state = {
      isDark:true
    }
    this.toggleActive = this.toggleActive.bind(this);
  }
  componentWillUnmount() {
    document.body.classList.remove('menu-open')
  }
  toggleActive() { 
    this.setState({
      isDark: ! this.state.isDark
    })
    document.body.classList.toggle('menu-open', this.state.isDark)
  }
  render() {
    return (
      <>
        <div className='body-overlay'></div>
      <header className='header'>
       
          <Navbar bg="primary" variant="dark" expand="xl">
            <Container>
              <Navbar.Brand href='/'>
                <Logo />
              </Navbar.Brand>
              <NabbarTop />
              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.toggleActive}>
                <div></div>
                <div></div>
                <div></div>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto navbar-top">
                  <GaragesDropDown />
                  <CarportsDropDown />
                  <BarnDropDown />
                  <OtherDropDown />
                  <PaymentDropDown />
                  <ResourcesDropDown />
                  <DealersDropDown />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
       
      </header>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
