import React, { useState, useEffect } from "react"
import {ArrowRight} from '../../Icons'
import ResourcesDropdown from "../../../images/menu_images/resources-dropdown.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby'

const ResourcesDropDown = (props) => {
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    }, 0)

  })
  return (
    <NavDropdown title="Resources" id="basic-nav-dropdown">
      <Row noGutters>
        <Col lg="4">          
          <Link className='dropdown-item' to='/roof-styles' >Roof Styles <ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/certified-buildings' >Certified Buildings <ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/site-preparation' >Site Preparation <ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
          <Link className='dropdown-item' to='/faq' >FAQ's<ArrowRight stroke='#A7A6B7' ArrowDir='right' width="16px" height="16px" /></Link>
        </Col>
        <Col className='dropdown-menu-rightSide-cnt'>
          <div className='dropdown-menu-rightSide'>
            <div className='innnerItem'>
              <h5 className='title'>Resources</h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && <div style={{ position: 'absolute', background: 'gray', left: 0, right: 0, display: 'flex', top: '0', bottom: '0', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>Loading</div>}
                {windowLoaded && <img src={ResourcesDropdown} alt='Resources' className="main-img" />}                
              </figure>
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )
}

export default ResourcesDropDown