import React from "react"
import Ripples from "react-ripples"
import { CallIcon } from "../Icons"
import ThreedImage from "../../images/threed.png"

const NabbarTop = props => {
  return (
    <div className="navbar-custom">
      <ul className="nav navbar-form">
        <li className="nav-item building-btn">
          <Ripples>
            <a className="btn-icon building" href="https://carportrus.sensei3d.com/" target="_blank" rel="noopener noreferrer">
              <img src={ThreedImage} alt='3D' />
              <div className='content-box'>
                <span className='title'>Design your</span>
                <span className='subtitle'>Building</span>
              </div>
            </a>
          </Ripples>
        </li>

        <li className="nav-item cta-btn">
          <Ripples>
            <a className="call-btn btn-icon" href="tel:8773304846">
              <CallIcon stroke="#fff" width="34px" height="34px" />
              <div className="content-box">
                <span className="text">(877) 330-4846</span>
              </div>
            </a>
          </Ripples>
        </li>
      </ul>
    </div>
  )
}

export default NabbarTop
