import React, { useEffect, useState } from "react"
import { ArrowRight } from "../../Icons"
import MetalCarportDropdown from "../../../images/menu_images/metal-carport-dropdown.jpg"
import PalceHolder3_2 from "../../../images/blank-16x9.png"
import { NavDropdown, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const CarportsDropDown = props => {
  const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    }, 0)
  })
  return (
    <NavDropdown title="Carports" id="basic-nav-dropdown">
      <Row noGutters>
        <Col lg="4">
          <Link
            className="dropdown-item"
            to="/metal-carports/single-car-carports"
          >
            Single Car Carports
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link
            className="dropdown-item"
            to="/metal-carports/double-car-carports"
          >
            Double Car Carports
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link
            className="dropdown-item"
            to="/metal-carports/triple-wide-carports"
          >
            Triple Wide Carports
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link className="dropdown-item" to="/metal-carports/rv-carports">
            RV Carports
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
          <Link className="dropdown-item view" to="/metal-carports">
            View All Metal Carports
            <ArrowRight
              stroke="#A7A6B7"
              ArrowDir="right"
              width="16px"
              height="16px"
            />
          </Link>
        </Col>
        <Col className="dropdown-menu-rightSide-cnt">
          <div className="dropdown-menu-rightSide">
            <div className="innnerItem">
              <h5 className="title">Metal Carports</h5>
              <figure>
                <img src={PalceHolder3_2} alt="..." className="img-full" />
                {!windowLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      background: "gray",
                      left: 0,
                      right: 0,
                      display: "flex",
                      top: "0",
                      bottom: "0",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    Loading
                  </div>
                )}
                {windowLoaded && (
                  <img
                    src={MetalCarportDropdown}
                    alt="Metal Carports"
                    className="main-img"
                  />
                )}               
              </figure>
              <Link
                to="/metal-carports"
                className="text-secondary btn btn-link icon-right"
              >
                Explore{" "}
                <ArrowRight
                  stroke="#cb0000"
                  ArrowDir="right"
                  width="16px"
                  height="16px"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </NavDropdown>
  )
}

export default CarportsDropDown
