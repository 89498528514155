import ListArrow from "../../images/BulletIcon.svg"
import ListBulletArrow from "../../images/bullet-arrow.png"
import styled from "styled-components"

export const Section = styled.section`
  background-image:${props => props.bgImage};
  background:${props => props.bg};
  background-size:${props => props.bgSize};
  margin-top:${props => props.mt};
  margin-bottom:${props => props.mb};
  padding-top:${props => props.pt};
  padding-bottom:${props => props.pb};
  text-align:${props => props.textAlign};
  &:before{
    display:${props => (props.bgBefore ? 'block' : 'none')};
    content:'';
    position:absolute;
    background-image:${props => props.bgBefore};
    background-size:${props => props.bgSize};
    @media(max-width:767px){
      display:none
    }
  }
  &:after{
    display:${props => (props.bgAfter ? 'block' : 'none')};
    content:'';
    position:absolute;
    background-image:${props => props.bgAfter};
    background-size:${props => props.bgSize};
  }
`

export const Div = styled.div`
  background:${props => props.bg};
  background-size:${props => props.bgSize};
  margin-top:${props => props.mt};
  margin-bottom:${props => props.mb};
  padding-top:${props => props.pt};
  padding-bottom:${props => props.pb};
  text-align:${props => props.textAlign};

  &:before{
    display:${props => (props.bgBefore ? 'block' : 'none')};
    content:'';
    position:${props=>props.Position};
    background:${props => props.bgBefore};
    background-size:${props => props.bgSize};
  }
  &:after{
    display:${props => (props.bgAfter ? 'block' : 'none')};
    content:'';
    position:${props=>props.Position};
    background:${props => props.bgAfter};
    background-size:${props => props.bgSize};
    
  }
`

export const MarkdownContent = styled.div`
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:0 auto;
	padding-left:${props=>props.pl};
  padding-right:${props=>props.pr};
  & span{
    font-size: 18px;
    margin-bottom: 5px;
    display: block;
  }
	& h1 {
		font-size: 42px;
		line-height:52px;
		font-weight: bold;
    margin-bottom:10px;
    color:#1B1464;
    text-transform: uppercase;    
		@media(max-width: 767px){
			font-size: 32px;
			line-height:42px;
		}
  }
  
	& h2 {
		font-size: 32px;
		line-height:42px;
		font-weight: bold;
    margin-bottom:10px;
    color:#1B1464;
    text-transform: uppercase;    
		@media(max-width: 767px){
			font-size: 28px;
			line-height:38px;
		}
	}
  
	& h3 {
		font-size: 26px;
		line-height: 36px;
		font-weight: 500;
    margin-bottom:10px;
    color:#1B1464;
		@media(max-width: 767px){
			font-size: 22px;
			line-height:32px;
		}
	}

	& p {
		text-align: ${props=>props.textAlign};
		color: ${props=>props.color};
		margin-bottom:30px;
		font-size: 16px;
    line-height: 26px;
    & strong{
      color:#363544;
      font-weight:700;
    }
	}
	& ul {
		list-style: none;		
    color:#565969;
    margin-bottom: 30px;
    padding:0;
    font-size: 16px;
    line-height: 26px;
		& li {
			padding-left:20px;
			vertical-align: text-top;
			margin:0;
			line-height: 26px;
			+ li{
				margin-top:15px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
		}
	}
`
MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0"

}

export const ListBulletGroup = styled.ul`
  list-style: none;		
  color:#000;
  margin-bottom: 0;
  padding:0;
  font-size: 16px;
  line-height: 26px;
  font-weight:600;
  
`
export const ListBulletItemGroup = styled.li`
  padding-left:20px;
  vertical-align: text-top;
  margin:0;
  line-height: 20px;
  + li{
    margin-top:10px;
  }
  &:before {
    content: url(${ListBulletArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
`