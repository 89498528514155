import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import {
  FacebookIcon,
  EmailIcon,
  MapIcon,
  PhoneIcon
} from "../Icons"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterBottom = styled.div`
  border-top: 1px solid rgb(64, 67, 80);
  text-align: center;
  padding: 20px;
  color: #a7a6b7;
  margin-top: 50px;
`
const Footer = props => {
  return (
    <footer className="footer" data-aos="fade-in">
      <Container>
        <Row>
          <Col md={6} lg={3}>
            <div className="footer-top-cnt">
              <h5 className="footer-heading">
                <PhoneIcon stroke="#fff" fill="#fff" />
                <span>Call Us</span>
              </h5>
              <div className="footer-text">                
                <a href="tel:8773304846">Phone: (877) 330-4846</a>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
          <div className='footer-top-cnt'>
            <h5 className='footer-heading'><EmailIcon stroke='#fff' fill='#fff' /><span>Email Us</span></h5>
              <a className='footer-text' href="mailto:carportrus@gmail.com" target="_blank" rel="noopener noreferrer">carportrus@gmail.com</a>
              </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="footer-top-cnt">
              <h5 className="footer-heading">
                <MapIcon stroke="#fff" fill="#fff" />
                <span>Find Us</span>
              </h5>
              <p className="footer-text">
                915, Newsome St. Mount Airy, NC 27030
              </p>
            </div>
          </Col>
          <Col md={6} lg={{span:3, offset:0}}>
            <ul className='social-icons'>
              <li className='facebook'><Link to='https://www.facebook.com/CarportRUs' target="_blank" rel="noopener noreferrer"><FacebookIcon fill='#fff' /></Link></li>
              {/* <li className='twitter'><Link to='https://twitter.com/' target="_blank" rel="noopener noreferrer"><TwitterIcon fill='#fff' /></Link></li>
              <li className='youtube'><Link to='https://www.youtube.com/' target="_blank" rel="noopener noreferrer"><YoutubeIcon fill='#fff' /></Link></li>
              <li className='instagram'><Link to='https://www.instagram.com/' target="_blank" rel="noopener noreferrer"><InstgramIcon stroke='#fff' /></Link></li> */}
            </ul>
          </Col>
        </Row>
        <div className="footer-top">
          <Row>
            <Col xs={6} sm={6} lg={3}>
              <h5>Our Buildings</h5>
              <ul>
                <li>
                  <Link to="/metal-carports">Carports</Link>
                </li>
                <li>
                  <Link to="/metal-garages">Garages</Link>
                </li>
                <li>
                  <Link to="/metal-barns">Barns</Link>
                </li>
                <li>
                  <Link to="/metal-buildings">Metal Buildings</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <h5>Resources</h5>
              <ul>
                <li>
                  <Link to="/roof-styles">Roof Styles</Link>
                </li>
                <li>
                  <Link to="/certified-buildings">Certified Buildings</Link>
                </li>
                <li>
                  <Link to="/site-preparation">Site Preparation</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <Link to="/rent-to-own">Rent to Own</Link>
                </li>
                <li>
                  <Link to="/financing">Financing</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <h5>Other Links</h5>
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/sitemap">Site Map</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
      <FooterBottom>
        <Container>
          © {new Date().getFullYear()} Carports R Us. All Rights
          Reserved.
        </Container>
      </FooterBottom>
    </footer>
  )
}

export default Footer
